import { useParams } from 'react-router-dom';
import { Button, Card, ContentContainer, PageContainer } from '@dayinsure/components';
import React, { useMemo } from 'react';
import { formatInUKLocalTime, useNavigateWithReferrer } from '@dayinsure/shared';
import { usePolicyQuery } from '../../../hooks';
import { MotorClaimDto, PolicyDriverDto } from '../../../api/v1';

const testId = 'policy-claims';
export const PolicyClaims = () => {
  const { id } = useParams<{ id: string }>();
  const { data: policy } = usePolicyQuery(id);
  const mainDriver = policy?.drivers?.find(
    (driver: PolicyDriverDto) => driver.isMainDriver
  );
  const navigate = useNavigateWithReferrer();
  const handleNavigate = () => {
    navigate(`/policies/${id}`);
  };
  const renderclaimCard = useMemo(
    () =>
      mainDriver?.previousMotorClaims?.map((claim: MotorClaimDto) => (
        <Card
          key={`${claim.type?.code}${claim.date}`}
          paddingLevel="small"
          classNames="bg-content-background-prominent mb-6"
          testId={`${testId}_policy-info-claims_card`}
        >
          {/* <Card.CardListItem */}
          {/*  label="Claim number" */}
          {/*  icon="clipboard" */}
          {/*  testId={`${testId}_claim-number_list-item`} */}
          {/* > */}
          {/*  123/4567 */}
          {/* </Card.CardListItem> */}
          <Card.CardListItem
            label="Type of claim"
            icon="clipboard"
            testId={`${testId}_type-of-claim_list-item`}
          >
            {claim.type?.description}
          </Card.CardListItem>
          <Card.CardListItem
            label="Type of claim"
            icon="date"
            testId={`${testId}_claim-date_list-item`}
          >
            {formatInUKLocalTime('dd/MM/yyyy', claim.date)}
          </Card.CardListItem>
        </Card>
      )),
    [mainDriver?.previousMotorClaims]
  );

  return (
    <PageContainer className="font-raleway">
      <h1
        className="my-8 text-xl text-center lg:my-12 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Policy claims
      </h1>
      <ContentContainer>
        <h2 className="mb-4 font-raleway text-lg">Previous claims</h2>
        {renderclaimCard}
        {/* <Button */}
        {/*  classNames="mb-8 mt-6" */}
        {/*  id={`${testId}_new-claim-button`} */}
        {/*  testId={`${testId}_new-claim-button`} */}
        {/*  fullWidth */}
        {/*  text="Make a new claim" */}
        {/*  icon={{ name: 'arrow-right', position: 'right' }} */}
        {/*  onClick={() => {}} */}
        {/* /> */}

        <Button
          variant="secondary"
          classNames="w-full mb-16"
          id={`${testId}_back-to-policy-button`}
          testId={`${testId}_back-to-policy-button`}
          text="Back to policy"
          onClick={handleNavigate}
        />
      </ContentContainer>
    </PageContainer>
  );
};
